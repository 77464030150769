import dynamic from 'next/dynamic';
import { CashbackComponentPropTypes } from 'components/common/CashbackComponent/interface';
import {
  CashbackLabel,
  CashbackWrapper,
} from 'components/common/CashbackComponent/styles';
import { trackEvent } from 'utils/analytics';
import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'const/index';
import { strings } from 'const/strings';
import CashbackIcon from 'assets/cashbackCoin';
import InfoIconWrapped from 'assets/infoIconWrapped';

const Tooltip = dynamic(() => import('components/common/Tooltip/index'), {
  ssr: false,
});

const CashbackComponent = ({
  cashbackAmount,
  isSportsExperiment,
  id,
}: CashbackComponentPropTypes) => {
  const trackHover = () => {
    trackEvent({
      eventName: ANALYTICS_EVENTS.TOOLTIP_VIEWED,
      [ANALYTICS_PROPERTIES.TOOLTIP_TYPE]: 'cashback',
      [ANALYTICS_PROPERTIES.TGID]: id,
    });
  };

  if (!cashbackAmount) return null;

  return (
    <CashbackWrapper isSportsExperiment={isSportsExperiment}>
      <CashbackIcon />
      <CashbackLabel>
        {strings.formatString(strings.GET_CASHBACK, `${cashbackAmount}`)}
        {!isSportsExperiment && (
          <Tooltip
            trigger={InfoIconWrapped}
            onHover={trackHover}
            content={`${strings.formatString(
              strings.CASHBACK_INFO,
              cashbackAmount
            )}`}
          />
        )}
      </CashbackLabel>
    </CashbackWrapper>
  );
};

export default CashbackComponent;
