const CashbackCoin = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M8.68905 15.4683C5.39415 15.4683 1 13.247 1 8.80564C1 4.13445 4.09106 1.57282 8.68905 1.57282V15.4683Z"
      fill="#E29700"
    />
    <path
      d="M8.68892 1.81863C12.0733 1.81863 14.8168 4.8178 14.8168 8.51747C14.8168 12.2171 12.0733 15.2163 8.68892 15.2163C5.30458 15.2163 2.56104 12.2171 2.56104 8.51747C2.56104 4.8178 5.30458 1.81863 8.68892 1.81863Z"
      fill="#FFCE00"
    />
    <path
      d="M8.68881 1.56445C5.16958 1.56445 2.30664 4.68357 2.30664 8.51758C2.30664 12.3516 5.16958 15.4707 8.68881 15.4707C12.208 15.4707 15.071 12.3516 15.071 8.51758C15.071 4.68357 12.208 1.56445 8.68881 1.56445ZM8.68881 14.9623C5.45003 14.9623 2.81521 12.0713 2.81521 8.51777C2.81521 4.96422 5.45003 2.07321 8.68881 2.07321C11.9276 2.07321 14.5624 4.96422 14.5624 8.51777C14.5624 12.0713 11.9276 14.9623 8.68881 14.9623Z"
      fill="#E29700"
    />
    <path
      d="M8.68926 3.71059C6.24463 3.71059 4.25586 5.86699 4.25586 8.51774C4.25586 11.1685 6.24463 13.3247 8.68926 13.3247C11.1339 13.3247 13.1227 11.1683 13.1227 8.51774C13.1227 5.86718 11.1339 3.71059 8.68926 3.71059ZM8.68926 12.4771C6.71206 12.4771 5.10329 10.7009 5.10329 8.51756C5.10329 6.33423 6.71206 4.55802 8.68926 4.55802C10.6665 4.55802 12.2752 6.33423 12.2752 8.51756C12.2752 10.7009 10.6665 12.4771 8.68926 12.4771Z"
      fill="#E29700"
    />
  </svg>
);
export default CashbackCoin;
